<style lang="less" scoped>
  .home {
    line-height: 400px;
    text-align: center;
    color: #333;
    font-size: 30px;
    text-align: center;
  }
</style>
<template>
  <div class="home">
    欢迎登录，LWK项目管理系统
    <!-- <my-list :list="list" :livingdata="[0, 1]"></my-list>
    <my-list2 :list="list2" livingdata="0"></my-list2> -->
  </div>
</template>

<script>
  //   import MyList from '@/components/list/index.vue'
  //   import MyList2 from '@/components/list/index2.vue'
  //   import { onMounted, ref } from 'vue'
  //   export default {
  //     components: { MyList, MyList2 },
  //     setup() {
  //       const list = ['B', 'B', 'P', 'P', 'T', 'B', 'T']
  //       let listArr = []
  //       let key = 'B'
  //       const getNum = () => {
  //         let listf = []
  //         list.forEach((res) => {
  //           if (res == key || (key != res && res == 'T')) {
  //             if (res == 'T') {
  //               key = res
  //             }
  //             listf.push(res)
  //             return false
  //           } else {
  //             listf = []
  //             listf.push(res)
  //             key = res
  //           }
  //           listArr.push(listf)
  //         })

  //         console.log(listArr, 's')
  //       }
  //       onMounted(() => {
  //         getNum()
  //       })
  //       const list2 = ref([
  //         { id: 1, net_flag: true, haha: 'asdf' },
  //         { id: 2, net_flag: false, haha: 'aaaa' },
  //       ])
  //       return {
  //         list,
  //         list2,
  //       }
  //     },
  //   }
  //
</script>
